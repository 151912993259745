
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as about96auGzrvjQMeta } from "/home/les/dev/blob/pages/about.vue?macro=true";
import { default as adminJTXzERBdtgMeta } from "/home/les/dev/blob/pages/admin.vue?macro=true";
import { default as _91id_93aEjSETYs6yMeta } from "/home/les/dev/blob/pages/b/[id].vue?macro=true";
import { default as blobs1IQF5ZbfV9Meta } from "/home/les/dev/blob/pages/blobs.vue?macro=true";
import { default as indexEW2jGwVlupMeta } from "/home/les/dev/blob/pages/index.vue?macro=true";
import { default as _91id_938ko52qpyheMeta } from "/home/les/dev/blob/pages/p/[id].vue?macro=true";
import { default as _91id_93MeDdWvQn44Meta } from "/home/les/dev/blob/pages/s/[id].vue?macro=true";
import { default as search_testhUsSUyJGToMeta } from "/home/les/dev/blob/pages/search_test.vue?macro=true";
import { default as searchCSTRaKS2ChMeta } from "/home/les/dev/blob/pages/search.vue?macro=true";
import { default as sharehxEW43ocOQMeta } from "/home/les/dev/blob/pages/share.vue?macro=true";
import { default as signinSg6EIH0oOWMeta } from "/home/les/dev/blob/pages/signin.vue?macro=true";
import { default as sourceyTpka1YV6vMeta } from "/home/les/dev/blob/pages/source.vue?macro=true";
import { default as _91id_93MqKxRswHClMeta } from "/home/les/dev/blob/pages/tag/[id].vue?macro=true";
import { default as tagsAf2oslkSmGMeta } from "/home/les/dev/blob/pages/tags.vue?macro=true";
import { default as widgetZURxCkFwjQMeta } from "/home/les/dev/blob/pages/widget.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/home/les/dev/blob/pages/about.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: adminJTXzERBdtgMeta || {},
    component: () => import("/home/les/dev/blob/pages/admin.vue")
  },
  {
    name: "b-id",
    path: "/b/:id()",
    component: () => import("/home/les/dev/blob/pages/b/[id].vue")
  },
  {
    name: "blobs",
    path: "/blobs",
    component: () => import("/home/les/dev/blob/pages/blobs.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/les/dev/blob/pages/index.vue")
  },
  {
    name: "p-id",
    path: "/p/:id()",
    component: () => import("/home/les/dev/blob/pages/p/[id].vue")
  },
  {
    name: "s-id",
    path: "/s/:id()",
    component: () => import("/home/les/dev/blob/pages/s/[id].vue")
  },
  {
    name: "search_test",
    path: "/search_test",
    component: () => import("/home/les/dev/blob/pages/search_test.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: searchCSTRaKS2ChMeta || {},
    component: () => import("/home/les/dev/blob/pages/search.vue")
  },
  {
    name: "share",
    path: "/share",
    component: () => import("/home/les/dev/blob/pages/share.vue")
  },
  {
    name: "signin",
    path: "/signin",
    component: () => import("/home/les/dev/blob/pages/signin.vue")
  },
  {
    name: "source",
    path: "/source",
    component: () => import("/home/les/dev/blob/pages/source.vue")
  },
  {
    name: "tag-id",
    path: "/tag/:id()",
    component: () => import("/home/les/dev/blob/pages/tag/[id].vue")
  },
  {
    name: "tags",
    path: "/tags",
    component: () => import("/home/les/dev/blob/pages/tags.vue")
  },
  {
    name: "widget",
    path: "/widget",
    component: () => import("/home/les/dev/blob/pages/widget.vue")
  }
]